angular.module('app').controller('mapController',
    ["$scope", "$window", "$log", "bookingService", "uiGmapGoogleMapApi", function ($scope, $window, $log, bookingService, uiGmapGoogleMapApi) {
        var ctrl = this;
        var conciergeLocation;


        (function init() {
            loadDefaultFromLocation().then(loadMap);
        }());


        function loadDefaultFromLocation() {
            return bookingService.getDefaultLocation()
                .then(function (location) {
                    if (location) {
                        conciergeLocation = location;
                        loadMap();
                    }
                })
                ['catch'](function (error) {
                $log.error('error: %o', error);
            });
        }

        function loadMap() {

            $scope.map = {
                center: {
                    latitude: conciergeLocation.latitude,
                    longitude: conciergeLocation.longitude
                },
                zoom: 12,
                options: {
                    draggable: true,
                    mapTypeControl: false,
                    scrollwheel: false,
                    streetViewControl: false,
                    overviewMapControl: false,
                    zoomControl: true
                },
                markers: {
                    concierge: {
                        id: conciergeLocation.locationReference,
                        coords: {latitude: conciergeLocation.latitude, longitude: conciergeLocation.longitude},
                        options: {
                            clickable: false,
                            draggable: false,
                            zIndex: 500,
                            icon: '/static/images/mkr-hail.png',
                        }
                    },
                    drivers: []
                }
            };

            $scope.$watchCollection(function () {
                return $scope.$parent.ctrl.activeBookings;
            }, function (value) {
                updateDriversEnRoute();
            }, true);

            $scope.$watch(function () {
                return $scope.$parent.ctrl.defaultLocation;
            }, function (location) {
                if (angular.isDefined(location)) {
                    conciergeLocation = location;
                    $scope.map.center = {latitude: conciergeLocation.latitude, longitude: conciergeLocation.longitude};
                    $scope.map.markers.concierge.coords = {
                        latitude: conciergeLocation.latitude,
                        longitude: conciergeLocation.longitude
                    };
                }
            });

        }

        // Generate a guid as the latest version of google maps requires unique ids
        function generateGuid() {
            return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function (c) {
                var num = Math.random() * 16 | 0, v = c === 'x' ? num : (num & 0x3 | 0x8);

                return v.toString(16);
            });
        }


        function updateDriversEnRoute() {
            var models = _.chain($scope.$parent.ctrl.activeBookings)
                .sortBy(function (booking) {
                    return +booking.requestedPickup;
                })  // sort them in the same order as the dashboard
                .map(function (booking, index) {
                    booking.index = '' + (index + 1);
                    return booking;
                })  // number them same as dashboard
                .filter(function (booking) {
                    return booking.status == 'EN_ROUTE_TO_PICKUP' || booking.status == 'APPROACHING_PICKUP' || booking.status == 'ARRIVED_AT_PICKUP';
                })  // now cull list to only those with drivers en-route
                .value();

            var getLatLng = function (booking) {
                return {
                    latitude: booking.progress.driverPosition.latitude,
                    longitude: booking.progress.driverPosition.longitude
                };
            };

            updateMarkers(models, getLatLng);
        }

        function updateMarkers(models, getLatLng) {

            var modelIds = _.map(models, "bookingReference");
            var driverIds = _.map($scope.map.markers.drivers, "bookingReference");
            var idsToRemove = _.difference(driverIds, modelIds);

            _.each(idsToRemove, function (id) {
                if (id) {
                    _.remove($scope.map.markers.drivers, function (driver) {
                        return (driver && driver.id === id);
                    });
                }
            });

            // update or add new markers
            var reverseModels = _.reverse(models);
            _.each(reverseModels, function (model) {
                var driverMarker = _.find($scope.map.markers.drivers, function (driverMarker) {
                    return driverMarker && driverMarker.bookingReference == model.bookingReference;
                });
                if (driverMarker) {
                    driverMarker.coords = getLatLng(model);
                    driverMarker.options.labelContent = model.bookingReference.slice(-3);
                } else {


                    var marker = {
                        id: model.bookingReference,
                        bookingReference: model.bookingReference,
                        coords: getLatLng(model),
                        icon: "/static/images/mkr-booking.png",
                        options: {
                            zIndex: 400,
                            clickable: false,
                            draggable: false,
                            labelClass: 'marker_labels',
                            labelAnchor: '18 38',
                            labelContent: model.bookingReference.slice(-3),
                        }
                    };

                    $scope.map.markers.drivers.push(marker);
                }
            });
        }
    }]);